import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { ErrorMessage } from "formik";
import SectionIntro from "@components/SectionIntro";
import sanitizeTitle from "@shared/sanitizeTitle";
function RadioInputs(props) {
    const { field: { name, value }, form: { setFieldValue }, options, sectionIntro } = props;
    const renderOption = (option) => {
        const title = sanitizeTitle(`${name}-${option}`);
        return (_jsxs("div", { className: "radio__control", children: [_jsx("input", { id: title, type: "radio", name: name, value: option, onChange: () => setFieldValue(name, option), checked: value === option }), _jsx("label", { htmlFor: title, className: "radio__label", children: option })] }, `input-${title}`));
    };
    return (_jsxs("div", { className: "form__group", children: [_jsx(SectionIntro, { innerHtml: sectionIntro }), _jsx("div", { className: "radio__group", children: options && options.map(renderOption) }), _jsx(ErrorMessage, { component: "div", className: "form__error", name: name })] }));
}
RadioInputs.defaultProps = {
    sectionIntro: null,
};
export default RadioInputs;
